@use '../base/vars'as *;

.top-bnr__lists{
    display: grid;
    grid-template-columns: 1fr;
    gap: 40px;
    margin-bottom: 60px;

    @include media(sm){
        grid-template-columns: repeat(2, 1fr);
        margin-bottom: 80px;
    }


    .bnr-list__item{
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;

        a{
            display: inline-block;
        }
    }

    .hiroshimamazda_01_yoko{

        img{
            max-width: 170px;
        }
    }
}


.maxWidth180{
    max-width: 180px;
    display: block;
    margin: 0 auto;

    img{
        width: 100%;
    }
}

.maxWidth250{
    max-width: 250px;
    display: block;
    margin: 0 auto;

    img{
        width: 100%;
    }
}

.maxWidth260{
    max-width: 260px;
    display: block;
    margin: 0 auto;

    img{
        width: 100%;
    }
}

.maxWidth280{
    max-width: 280px;
    display: block;
    margin: 0 auto;

    img{
        width: 100%;
    }
}

.maxWidth300{
    max-width: 300px;
    display: block;
    margin: 0 auto;

    img{
        width: 100%;
    }
}

.maxWidth380{
    max-width: 380px;
    display: block;
    margin: 0 auto;

    img{
        width: 100%;
    }
}

.bnr-hirosima_kokusaigakuin{

    img{
        width: 100%;
    }
}
