.top-bnr__lists {
  display: grid;
  grid-template-columns: 1fr;
  gap: 40px;
  margin-bottom: 60px;
}
@media screen and (min-width: 769px) {
  .top-bnr__lists {
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 80px;
  }
}
.top-bnr__lists .bnr-list__item {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.top-bnr__lists .bnr-list__item a {
  display: inline-block;
}
.top-bnr__lists .hiroshimamazda_01_yoko img {
  max-width: 170px;
}

.maxWidth180 {
  max-width: 180px;
  display: block;
  margin: 0 auto;
}
.maxWidth180 img {
  width: 100%;
}

.maxWidth250 {
  max-width: 250px;
  display: block;
  margin: 0 auto;
}
.maxWidth250 img {
  width: 100%;
}

.maxWidth260 {
  max-width: 260px;
  display: block;
  margin: 0 auto;
}
.maxWidth260 img {
  width: 100%;
}

.maxWidth280 {
  max-width: 280px;
  display: block;
  margin: 0 auto;
}
.maxWidth280 img {
  width: 100%;
}

.maxWidth300 {
  max-width: 300px;
  display: block;
  margin: 0 auto;
}
.maxWidth300 img {
  width: 100%;
}

.maxWidth380 {
  max-width: 380px;
  display: block;
  margin: 0 auto;
}
.maxWidth380 img {
  width: 100%;
}

.bnr-hirosima_kokusaigakuin img {
  width: 100%;
}

.submain .submain__ttl .submain__ttl_work {
  max-width: 200px;
  margin: 0 auto;
}

@media screen and (min-width: 768px) {
  .submain .submain__ttl .submain__ttl_work {
    max-width: 100%;
    margin: 0 auto;
  }
}