@use "sass:math";

///*------------------------------------*//
//    Fonts
//*------------------------------------*//
$font-noto: 'Noto Sans JP', sans-serif;

$line-height: 1.5;



///*------------------------------------*//
//    Colors
//*------------------------------------*//
$white:#ffffff;
$black:#333333;



///*------------------------------------*//
//   wrpper/container
//*------------------------------------*//
$wrapper: 1280;
$container: 1000;



///*------------------------------------*//
//   z-index
//*------------------------------------*//
$bg: 1;
$main: 10;
$menu: 100;
$header: 1000;
$modal: 10000;



///*------------------------------------*//
//     breakpoints
//*------------------------------------*//
$breakpoints: (
  sm: 'screen and (min-width: 769px)',
  md: 'screen and (min-width: 1000px)',
  lg: 'screen and (min-width: 1200px)',
  xl: 'screen and (min-width: 1366px)',
  xxl: 'screen and (min-width: 1500px)',
) !default;



///*------------------------------------*//
//     Mixin
//*------------------------------------*//
//メディアクエリ挿入
@mixin media($breakpoint) {
  @media #{map-get($breakpoints, $breakpoint)} {
      @content;
  }
}

//フォントサイズ計算
@function rem($size, $base: 16) {
  @return math.div($size, $base)+rem;
}

//pxをvwに変換するfunction
@function vw($size, $viewport: $wrapper) {
  $rate: calc(100 / $viewport);
  @return $rate * $size * 1vw;
}

//hover
@mixin hover {
  transition: all .5s ease-out;

  &:focus {
      @content;
  }

  @media (hover: hover) {
      &:hover {
          @content;
      }
  }
}
