.submain{

    .submain__ttl .submain__ttl_work{
        max-width: 200px;
        margin: 0 auto;
    }
}


@media screen and (min-width: 768px){
    .submain{

        .submain__ttl .submain__ttl_work{
            max-width: 100%;
            margin: 0 auto;
        }
    }
}
